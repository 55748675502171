.bp3-context-menu2-virtual-target{
  position:fixed; }
.bp3-popover2{
  -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
          box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  -webkit-transform:scale(1);
          transform:scale(1);
  border-radius:3px;
  display:inline-block;
  z-index:20; }
  .bp3-popover2 .bp3-popover2-arrow{
    height:30px;
    position:absolute;
    width:30px; }
    .bp3-popover2 .bp3-popover2-arrow::before{
      height:20px;
      margin:5px;
      width:20px; }
  .bp3-popover2 .bp3-popover2-content{
    background:#ffffff; }
  .bp3-popover2 .bp3-popover2-content,
  .bp3-popover2 .bp3-heading{
    color:inherit; }
  .bp3-popover2 .bp3-popover2-arrow::before{
    -webkit-box-shadow:1px 1px 6px rgba(16, 22, 26, 0.2);
            box-shadow:1px 1px 6px rgba(16, 22, 26, 0.2); }
  .bp3-popover2 .bp3-popover2-arrow-border{
    fill:#10161a;
    fill-opacity:0.1; }
  .bp3-popover2 .bp3-popover2-arrow-fill{
    fill:#ffffff; }
  .bp3-popover2-enter > .bp3-popover2, .bp3-popover2-appear > .bp3-popover2{
    -webkit-transform:scale(0.3);
            transform:scale(0.3); }
  .bp3-popover2-enter-active > .bp3-popover2, .bp3-popover2-appear-active > .bp3-popover2{
    -webkit-transform:scale(1);
            transform:scale(1);
    -webkit-transition-delay:0;
            transition-delay:0;
    -webkit-transition-duration:300ms;
            transition-duration:300ms;
    -webkit-transition-property:-webkit-transform;
    transition-property:-webkit-transform;
    transition-property:transform;
    transition-property:transform, -webkit-transform;
    -webkit-transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
            transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-popover2-exit > .bp3-popover2{
    -webkit-transform:scale(1);
            transform:scale(1); }
  .bp3-popover2-exit-active > .bp3-popover2{
    -webkit-transform:scale(0.3);
            transform:scale(0.3);
    -webkit-transition-delay:0;
            transition-delay:0;
    -webkit-transition-duration:300ms;
            transition-duration:300ms;
    -webkit-transition-property:-webkit-transform;
    transition-property:-webkit-transform;
    transition-property:transform;
    transition-property:transform, -webkit-transform;
    -webkit-transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
            transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-popover2 .bp3-popover2-content{
    border-radius:3px;
    position:relative; }
  .bp3-popover2.bp3-popover2-content-sizing .bp3-popover2-content{
    max-width:350px;
    padding:20px; }
  .bp3-popover2-target + .bp3-overlay .bp3-popover2.bp3-popover2-content-sizing{
    width:350px; }
  .bp3-popover2.bp3-minimal{
    margin:0 !important; }
    .bp3-popover2.bp3-minimal .bp3-popover2-arrow{
      display:none; }
    .bp3-popover2.bp3-minimal.bp3-popover2{
      -webkit-transform:scale(1);
              transform:scale(1); }
      .bp3-popover2-enter > .bp3-popover2.bp3-minimal.bp3-popover2, .bp3-popover2-appear > .bp3-popover2.bp3-minimal.bp3-popover2{
        -webkit-transform:scale(1);
                transform:scale(1); }
      .bp3-popover2-enter-active > .bp3-popover2.bp3-minimal.bp3-popover2, .bp3-popover2-appear-active > .bp3-popover2.bp3-minimal.bp3-popover2{
        -webkit-transform:scale(1);
                transform:scale(1);
        -webkit-transition-delay:0;
                transition-delay:0;
        -webkit-transition-duration:100ms;
                transition-duration:100ms;
        -webkit-transition-property:-webkit-transform;
        transition-property:-webkit-transform;
        transition-property:transform;
        transition-property:transform, -webkit-transform;
        -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
                transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9); }
      .bp3-popover2-exit > .bp3-popover2.bp3-minimal.bp3-popover2{
        -webkit-transform:scale(1);
                transform:scale(1); }
      .bp3-popover2-exit-active > .bp3-popover2.bp3-minimal.bp3-popover2{
        -webkit-transform:scale(1);
                transform:scale(1);
        -webkit-transition-delay:0;
                transition-delay:0;
        -webkit-transition-duration:100ms;
                transition-duration:100ms;
        -webkit-transition-property:-webkit-transform;
        transition-property:-webkit-transform;
        transition-property:transform;
        transition-property:transform, -webkit-transform;
        -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
                transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover2.bp3-dark,
  .bp3-dark .bp3-popover2{
    -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
            box-shadow:0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-popover2.bp3-dark .bp3-popover2-content,
    .bp3-dark .bp3-popover2 .bp3-popover2-content{
      background:#30404d; }
    .bp3-popover2.bp3-dark .bp3-popover2-content,
    .bp3-popover2.bp3-dark .bp3-heading,
    .bp3-dark .bp3-popover2 .bp3-popover2-content,
    .bp3-dark .bp3-popover2 .bp3-heading{
      color:inherit; }
    .bp3-popover2.bp3-dark .bp3-popover2-arrow::before,
    .bp3-dark .bp3-popover2 .bp3-popover2-arrow::before{
      -webkit-box-shadow:1px 1px 6px rgba(16, 22, 26, 0.4);
              box-shadow:1px 1px 6px rgba(16, 22, 26, 0.4); }
    .bp3-popover2.bp3-dark .bp3-popover2-arrow-border,
    .bp3-dark .bp3-popover2 .bp3-popover2-arrow-border{
      fill:#10161a;
      fill-opacity:0.2; }
    .bp3-popover2.bp3-dark .bp3-popover2-arrow-fill,
    .bp3-dark .bp3-popover2 .bp3-popover2-arrow-fill{
      fill:#30404d; }

.bp3-popover2-arrow::before{
  border-radius:2px;
  content:"";
  display:block;
  position:absolute;
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg); }

.bp3-overlay-backdrop.bp3-popover2-backdrop{
  background:rgba(255, 255, 255, 0); }

.bp3-popover2-transition-container{
  opacity:1;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  z-index:20; }
  .bp3-popover2-transition-container.bp3-popover2-enter, .bp3-popover2-transition-container.bp3-popover2-appear{
    opacity:0; }
  .bp3-popover2-transition-container.bp3-popover2-enter-active, .bp3-popover2-transition-container.bp3-popover2-appear-active{
    opacity:1;
    -webkit-transition-delay:0;
            transition-delay:0;
    -webkit-transition-duration:100ms;
            transition-duration:100ms;
    -webkit-transition-property:opacity;
    transition-property:opacity;
    -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
            transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover2-transition-container.bp3-popover2-exit{
    opacity:1; }
  .bp3-popover2-transition-container.bp3-popover2-exit-active{
    opacity:0;
    -webkit-transition-delay:0;
            transition-delay:0;
    -webkit-transition-duration:100ms;
            transition-duration:100ms;
    -webkit-transition-property:opacity;
    transition-property:opacity;
    -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
            transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover2-transition-container:focus{
    outline:none; }
  .bp3-popover2-transition-container.bp3-popover2-leave .bp3-popover2-content{
    pointer-events:none; }

span.bp3-popover2-target{
  display:inline-block; }
.bp3-button-group:not(.bp3-minimal) > .bp3-popover2-target:not(:first-child) .bp3-button{
  border-bottom-left-radius:0;
  border-top-left-radius:0; }

.bp3-button-group:not(.bp3-minimal) > .bp3-popover2-target:not(:last-child) .bp3-button{
  border-bottom-right-radius:0;
  border-top-right-radius:0;
  margin-right:-1px; }

.bp3-button-group .bp3-popover2-target{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto; }

.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover2-target:first-child .bp3-button{
  border-radius:3px 3px 0 0; }

.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover2-target:last-child .bp3-button{
  border-radius:0 0 3px 3px; }

.bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover2-target:not(:last-child) .bp3-button{
  margin-bottom:-1px; }

.bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-popover2-wrapper:not(:last-child) .bp3-button{
  margin-right:1px; }

.bp3-dark .bp3-button-group.bp3-vertical > .bp3-popover2-wrapper:not(:last-child) .bp3-button{
  margin-bottom:1px; }
.bp3-control-group .bp3-popover2-target{
  border-radius:inherit; }
label.bp3-label .bp3-popover2-target{
  display:block;
  margin-top:5px;
  text-transform:none; }
.bp3-submenu .bp3-popover2-target{
  display:block; }
  .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start;
    border-radius:2px;
    color:inherit;
    line-height:20px;
    padding:5px 7px;
    text-decoration:none;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    color:inherit; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item > *{
      -webkit-box-flex:0;
          -ms-flex-positive:0;
              flex-grow:0;
      -ms-flex-negative:0;
          flex-shrink:0; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item > .bp3-fill{
      -webkit-box-flex:1;
          -ms-flex-positive:1;
              flex-grow:1;
      -ms-flex-negative:1;
          flex-shrink:1; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item::before,
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item > *{
      margin-right:7px; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item:empty::before,
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item > :last-child{
      margin-right:0; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item > .bp3-fill{
      word-break:break-word; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item:hover{
      background-color:rgba(167, 182, 194, 0.3);
      cursor:pointer;
      text-decoration:none; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-disabled{
      background-color:inherit;
      color:rgba(92, 112, 128, 0.6);
      cursor:not-allowed; }
    .bp3-dark .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item{
      color:inherit; }
      .bp3-dark .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item:hover{
        background-color:rgba(138, 155, 168, 0.15);
        color:inherit; }
      .bp3-dark .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-disabled{
        background-color:inherit;
        color:rgba(167, 182, 194, 0.6); }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary{
      color:#106ba3; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary .bp3-icon{
        color:inherit; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label{
        color:#106ba3; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary.bp3-active{
        background-color:#137cbd; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:active{
        background-color:#106ba3; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:hover::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary.bp3-active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label{
        color:#ffffff; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success{
      color:#0d8050; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success .bp3-icon{
        color:inherit; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success .bp3-menu-item-label{
        color:#0d8050; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success.bp3-active{
        background-color:#0f9960; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:active{
        background-color:#0d8050; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:hover::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:hover::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success.bp3-active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label{
        color:#ffffff; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning{
      color:#bf7326; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning .bp3-icon{
        color:inherit; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label{
        color:#bf7326; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning.bp3-active{
        background-color:#d9822b; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:active{
        background-color:#bf7326; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:hover::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning.bp3-active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label{
        color:#ffffff; }
    .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger{
      color:#c23030; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger .bp3-icon{
        color:inherit; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label{
        color:#c23030; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger.bp3-active{
        background-color:#db3737; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:active{
        background-color:#c23030; }
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:hover::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger.bp3-active::after,
      .bp3-submenu .bp3-popover2-target.bp3-popover2-open > .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label{
        color:#ffffff; }

.bp3-submenu.bp3-popover2{
  -webkit-box-shadow:none;
          box-shadow:none;
  padding:0 5px; }
  .bp3-submenu.bp3-popover2 > .bp3-popover2-content{
    -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
            box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-submenu.bp3-popover2, .bp3-submenu.bp3-popover2.bp3-dark{
    -webkit-box-shadow:none;
            box-shadow:none; }
    .bp3-dark .bp3-submenu.bp3-popover2 > .bp3-popover2-content, .bp3-submenu.bp3-popover2.bp3-dark > .bp3-popover2-content{
      -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
              box-shadow:0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
.bp3-tree-node-secondary-label .bp3-popover2-target{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }
.bp3-tooltip2{
  -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
          box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  -webkit-transform:scale(1);
          transform:scale(1); }
  .bp3-tooltip2 .bp3-popover2-arrow{
    height:22px;
    position:absolute;
    width:22px; }
    .bp3-tooltip2 .bp3-popover2-arrow::before{
      height:14px;
      margin:4px;
      width:14px; }
  .bp3-tooltip2 .bp3-popover2-content{
    background:#394b59; }
  .bp3-tooltip2 .bp3-popover2-content,
  .bp3-tooltip2 .bp3-heading{
    color:#f5f8fa; }
  .bp3-tooltip2 .bp3-popover2-arrow::before{
    -webkit-box-shadow:1px 1px 6px rgba(16, 22, 26, 0.2);
            box-shadow:1px 1px 6px rgba(16, 22, 26, 0.2); }
  .bp3-tooltip2 .bp3-popover2-arrow-border{
    fill:#10161a;
    fill-opacity:0.1; }
  .bp3-tooltip2 .bp3-popover2-arrow-fill{
    fill:#394b59; }
  .bp3-popover2-enter > .bp3-tooltip2, .bp3-popover2-appear > .bp3-tooltip2{
    -webkit-transform:scale(0.8);
            transform:scale(0.8); }
  .bp3-popover2-enter-active > .bp3-tooltip2, .bp3-popover2-appear-active > .bp3-tooltip2{
    -webkit-transform:scale(1);
            transform:scale(1);
    -webkit-transition-delay:0;
            transition-delay:0;
    -webkit-transition-duration:100ms;
            transition-duration:100ms;
    -webkit-transition-property:-webkit-transform;
    transition-property:-webkit-transform;
    transition-property:transform;
    transition-property:transform, -webkit-transform;
    -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
            transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover2-exit > .bp3-tooltip2{
    -webkit-transform:scale(1);
            transform:scale(1); }
  .bp3-popover2-exit-active > .bp3-tooltip2{
    -webkit-transform:scale(0.8);
            transform:scale(0.8);
    -webkit-transition-delay:0;
            transition-delay:0;
    -webkit-transition-duration:100ms;
            transition-duration:100ms;
    -webkit-transition-property:-webkit-transform;
    transition-property:-webkit-transform;
    transition-property:transform;
    transition-property:transform, -webkit-transform;
    -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
            transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tooltip2 .bp3-popover2-content{
    padding:10px 12px; }
  .bp3-tooltip2.bp3-popover2-placement-top .bp3-popover2-arrow{
    -webkit-transform:translateY(-3px);
            transform:translateY(-3px); }
  .bp3-tooltip2.bp3-popover2-placement-left .bp3-popover2-arrow{
    -webkit-transform:translateX(-3px);
            transform:translateX(-3px); }
  .bp3-tooltip2.bp3-popover2-placement-bottom .bp3-popover2-arrow{
    -webkit-transform:translateY(3px);
            transform:translateY(3px); }
  .bp3-tooltip2.bp3-popover2-placement-right .bp3-popover2-arrow{
    -webkit-transform:translateX(3px);
            transform:translateX(3px); }
  .bp3-tooltip2.bp3-dark,
  .bp3-dark .bp3-tooltip2{
    -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
            box-shadow:0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-tooltip2.bp3-dark .bp3-popover2-content,
    .bp3-dark .bp3-tooltip2 .bp3-popover2-content{
      background:#e1e8ed; }
    .bp3-tooltip2.bp3-dark .bp3-popover2-content,
    .bp3-tooltip2.bp3-dark .bp3-heading,
    .bp3-dark .bp3-tooltip2 .bp3-popover2-content,
    .bp3-dark .bp3-tooltip2 .bp3-heading{
      color:#394b59; }
    .bp3-tooltip2.bp3-dark .bp3-popover2-arrow::before,
    .bp3-dark .bp3-tooltip2 .bp3-popover2-arrow::before{
      -webkit-box-shadow:1px 1px 6px rgba(16, 22, 26, 0.4);
              box-shadow:1px 1px 6px rgba(16, 22, 26, 0.4); }
    .bp3-tooltip2.bp3-dark .bp3-popover2-arrow-border,
    .bp3-dark .bp3-tooltip2 .bp3-popover2-arrow-border{
      fill:#10161a;
      fill-opacity:0.2; }
    .bp3-tooltip2.bp3-dark .bp3-popover2-arrow-fill,
    .bp3-dark .bp3-tooltip2 .bp3-popover2-arrow-fill{
      fill:#e1e8ed; }
  .bp3-tooltip2.bp3-intent-primary .bp3-popover2-content{
    background:#137cbd;
    color:#ffffff; }
  .bp3-tooltip2.bp3-intent-primary .bp3-popover2-arrow-fill{
    fill:#137cbd; }
  .bp3-tooltip2.bp3-intent-success .bp3-popover2-content{
    background:#0f9960;
    color:#ffffff; }
  .bp3-tooltip2.bp3-intent-success .bp3-popover2-arrow-fill{
    fill:#0f9960; }
  .bp3-tooltip2.bp3-intent-warning .bp3-popover2-content{
    background:#d9822b;
    color:#ffffff; }
  .bp3-tooltip2.bp3-intent-warning .bp3-popover2-arrow-fill{
    fill:#d9822b; }
  .bp3-tooltip2.bp3-intent-danger .bp3-popover2-content{
    background:#db3737;
    color:#ffffff; }
  .bp3-tooltip2.bp3-intent-danger .bp3-popover2-arrow-fill{
    fill:#db3737; }

.bp3-tooltip2-indicator{
  border-bottom:dotted 1px;
  cursor:help; }
/*# sourceMappingURL=blueprint-popover2.css.map */